<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'index',
  mounted () {
    require('@/assets/js/base.js')
  }
}
</script>
